import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from '../translation';

const lng = new URLSearchParams(window.location.search).get('lang') || "en";

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng, 
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
