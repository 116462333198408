import React, { useState } from "react";
import classNames from "classnames";

const AccordionItem = ({ title, collapsedText }) => {

  const [toggled, setToggled] = useState(false);

  return (
    <div className={classNames("accordion-item", {
      toggled: toggled
    })}
         onClick={() => setToggled(prev => !prev)}>
      <div className="item-header">
        <h3 className="item-header-title">{title}</h3>

        <button className="item-header-btn">
          <svg className="icon" width="18" height="12" viewBox="0 0 18 12" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5 2L9 9.5L1.5 2" stroke="white" strokeWidth="3" strokeLinecap="round"></path>
          </svg>
        </button>
      </div>

      {toggled && <div className="accordion-collapse" dangerouslySetInnerHTML={{__html: collapsedText}} />}
    </div>
  );
};

export default AccordionItem;
