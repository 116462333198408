import { useEffect, useState, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useHttp } from "../hooks/http.hook";
import { MainContext } from "../contexts/main.context";

import Card from "../components/Card";
import ShopBasket from "../components/ShopBasket";
import { TERMS_CONDITIONS_TAB_INDEX } from "../constants";
import { sendPostMessageClientHeight } from "../helpers";

function Shop() {
  const { t } = useTranslation();
  const { mainDetails, dispatch } = useContext(MainContext);
  const { request } = useHttp();
  const [shop, setShop] = useState();
  const [emptyShoppingCart, setEmptyShoppingCart] = useState(false);
  const [basket, setBasket] = useState({
    items: [],
    totalStars: 0,
    availableStars: mainDetails.tour.available_stars,
  });

  useEffect(() => {
    request("/client/shop").then((res) => {
      setShop(res);
      sendPostMessageClientHeight();
    });

    document.querySelectorAll('.shop-terms-btn').forEach(item => {
      item.addEventListener('click', () => {

        dispatch({
          type: "SET_ACTIVE_TAB",
          payload: TERMS_CONDITIONS_TAB_INDEX,
        });
      })
    })

  }, []);

  const handleClaim = async (card) => {
    if (mainDetails.tour.shop_available !== 1) return;
    if (!(basket.availableStars >= card.price)) return;
    setEmptyShoppingCart(true);

    const items = [...basket.items, card];
    const totalStars = items.reduce((acc, item) => acc + item.price, 0);
    const availableStars = mainDetails.tour.available_stars - totalStars;

    setBasket({ items, totalStars, availableStars });
  };

  return (
    <>
      <section className="section-shop mb-16">
        <h2>{t("shop_title")}</h2>
        <p dangerouslySetInnerHTML={{ __html: t("shop_description") }} />

      </section>

      <div className="shop-wrapper">
        <div className="card-wrapper">
          {shop?.map((card, index) => (
            <Fragment key={index}>
              <Card
                image={card?.prize.images.desktop}
                title={card?.prize?.name}
                price={card?.price}
                buttonText={t("claim")}
                buttonOnClick={() => handleClaim(card)}
                buttonDisabled={mainDetails.tour.shop_available !== 1}
              />
            </Fragment>
          ))}
        </div>

        <ShopBasket
          emptyShoppingCart={emptyShoppingCart}
          toEmptyShoppingCart={() => setEmptyShoppingCart(false)}
          basket={basket}
          setBasket={setBasket}
        />
      </div>
    </>
  );
}

export default Shop;
