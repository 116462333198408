import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { MainContext } from "../contexts/main.context";
import { useHttp } from "../hooks/http.hook";
import { notifyError } from "../helpers/notification";
import classNames from "classnames";
import Button from "./partial/Button";
import GiftActiveBox from "../components/GiftActiveBox";
import {
  DIAMOND_BOX_IMG,
  GOLD_BOX_IMG,
  SILVER_BOX_IMG,
  BOX_TYPES,
  BOX_ACTIVE_EMPTY
} from "../constants";

const Gift = ({ next }) => {
  const { t } = useTranslation();
  const { request } = useHttp();
  const { mainDetails, dispatch } = useContext(MainContext);
  const [matrix, setMatrix] = useState(new Array(9).fill(false));
  const [disabledBox, setDisabledBox] = useState(false);
  const [giftBoxActiveId, setGiftBoxActiveId] = useState(null);

  const box = mainDetails?.box?.box;

  const sendOpenBox = async (id, clickedId) => {
    setGiftBoxActiveId(clickedId);

    const data = {
      city_box_id: id,
      index: clickedId,
    };

    setDisabledBox((prev) => !prev);

    request("/client/box/open", "POST", data)
      .then((res) => {
        setMatrix(res.matrix);
        dispatch({
          type: "UPDATE_CITY_BOX_STATUS",
          payload: res.client_city_box,
        });
      })
      .catch((err) => notifyError(t(err.code, err.message)));
  };

  const backButton = () => next();

  return (
    <div className="city-gift">
      <div className="gift-content">
        <img
          className="gift-content-img"
          src={
            box.type === BOX_TYPES.silver
              ? SILVER_BOX_IMG
              : box.type === BOX_TYPES.gold
              ? GOLD_BOX_IMG
              : DIAMOND_BOX_IMG
          }
          alt=""
        />
        <div>
          <h3 className="h2 text-blue">
            {box.type === BOX_TYPES.silver
              ? t("box_silver_name")
              : box.type === BOX_TYPES.gold
              ? t("box_gold_name")
              : t("box_diamond_name")}
          </h3>
          <p className="gift-content-description">
            {box.type === BOX_TYPES.silver
              ? t("box_silver_description")
              : box.type === BOX_TYPES.gold
              ? t("box_gold_description")
              : t("box_diamond_description")}
          </p>
        </div>
      </div>

      <div className="gift-box-wrapper">
        {console.log(matrix)}
        {matrix.map((item, index) => (
          <div
            key={index}
            className={classNames("gift-box", {
              active: giftBoxActiveId === index,
              disabled: disabledBox,
            })}
            onClick={() => sendOpenBox(mainDetails.box.id, index)}
          >
            {item ? (
              giftBoxActiveId === index ? (
                <GiftActiveBox win item={item} />
              ) : (
                <img
                  className="gift-box-img"
                  src={item?.images.desktop}
                  alt={item.name}
                />
              )
            ) : item === null ? (
              giftBoxActiveId === index ? (
                <GiftActiveBox />
              ) : (
                <img
                  className="gift-box-img empty"
                  src={BOX_ACTIVE_EMPTY}
                  alt="empty not clicked"
                />
              )
            ) : (
              <img
                className="gift-box-img"
                src={
                  box.type === BOX_TYPES.silver
                    ? SILVER_BOX_IMG
                    : box.type === BOX_TYPES.gold
                    ? GOLD_BOX_IMG
                    : DIAMOND_BOX_IMG
                }
                alt="closed"
              />
            )}
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center">
        <Button
          className="gift-box-btn"
          primary
          btnBase
          onClick={() => backButton()}
          buttonText={t("back")}
        />
      </div>
    </div>
  );
};

export default Gift;
