import { useState } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import Details from "../components/Details";
import Gift from "../components/Gift";
import Button from "../components/partial/Button";
import classNames from "classnames";

const CityDetails = (props) => {
  const [state, setState] = useState(true);

  const { city, close, className } = props;

  return (
    <div className="popup-overlay">
      <div
        className={classNames("popup", {
        [`${className}`]: className
        })}
      >
        <Button
          className="popup-btn btn-icon"
          buttonIcon="close"
          onClick={() => close()}
        />

        <SwitchTransition mode={"out-in"}>
          <CSSTransition
            key={state ? "Details" : "Gift"}
            addEndListener={(node, done) =>
              node.addEventListener("transitionend", done, false)
            }
            classNames="fade"
          >
            {state ? (
              <Details city={city} next={() => setState((state) => !state)} />
            ) : (
              <Gift next={() => setState((state) => !state)} />
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
};

export default CityDetails;
