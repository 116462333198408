import React from "react";
import TopPrizesItem from "./TopPrizesItem";
import useMatchMedia from "../hooks/useMatchMedia";
import { useTranslation } from "react-i18next";

const TopPrizes = ({ topPrizes }) => {
  const isMobile = useMatchMedia("991.98");
  const { t } = useTranslation();

  return (
    <div className="city-tops-prizes city-tops-block">
      <h2 className="tops-title">{t("top_prizes")}</h2>

      <div className="tops-item-wrapper">
        {topPrizes?.map(item => (
          <TopPrizesItem
            key={item.id}
            name={item.name}
            externalId={item.external_id}
            image={isMobile ? item.images.mobile : item.images.desktop}
          />
        ))}
      </div>
    </div>
  );
};

export default TopPrizes;
