export const kFormatter = (num) => {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
    : Math.sign(num) * Math.abs(num);
};

export const dateFormat = (timestamp) => {
  if (!timestamp) return "-";

  return new Date(timestamp * 1000).toLocaleDateString();
};

export const getPercent = (diff, progress) => (progress * 100) / diff;

export const getBox = (boxArr, boxName) => {
  let boxTypeCount = 0;
  let openedBoxCount = 0;

  for (let i = 0; i <= boxArr.length; i++) {
    if (boxArr[i]?.box.type === boxName && boxArr[i]?.is_available !== -1) {
      boxTypeCount++;
      if (boxArr[i]?.status === 2) {
        openedBoxCount++;
      }
    }
  }

  return { boxTypeCount, openedBoxCount };
};

export const updateStatusReaded = (unreadEvents, request) => {
  const eventIds = unreadEvents.map((element) => element.id);

  request("/client/event/status/read", "POST", { event_ids: eventIds }).then(
    (res) => res
  );
};

export const citiesChunks = (cities, cityRowItemCount) => {
  const citiesChunks = cities.reduce(
    (acc, city) => {
      const last = acc[acc.length - 1];
      if (last.items.length < cityRowItemCount) {
        last.items.push(city);
      } else {
        acc.push({ completed: false, items: [city] });
      }
      return acc;
    },
    [{ completed: false, items: [] }]
  );

  for (let i = 0; i < citiesChunks.length; i++) {
    if (citiesChunks[i].items.some((val) => val.status !== 1) && i >= 1) {
      citiesChunks[i - 1].completed = true;
    }
    if (i % 2 !== 0) citiesChunks[i].items.reverse();
  }

  return citiesChunks;
};

export const activeBoxCount = (boxs) => {
  const count = boxs?.filter(
    (item) => item?.status === 1 && item?.is_available !== -1
  ).length;

  return count > 0 && count;
};

export const sendPostMessageClientHeight = () => {
  setTimeout(() => {
    const clientHeight = document.getElementById("root").clientHeight + 130;
    window.parent.postMessage({ action: 'clientHeight', height: clientHeight }, "*");

    console.log('clientHeight = ', clientHeight);
  }, 400)
};
