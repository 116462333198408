import React from "react";
import { BOX_ACTIVE_EMPTY } from "../constants";
import { useTranslation } from "react-i18next";

const GiftActiveBox = ({ win, item }) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="active-title">
        {win ? t("box_win_title") : t("box_lose_title")}
      </p>
      {win && <p className="active-sub-title">{t("box_win_sub_title")}</p>}
      <img
        className="active-img"
        src={win ? item?.images.desktop : BOX_ACTIVE_EMPTY}
        alt="Empty"
      />
      <div className="active-text">
        {win ? (
          <>
            <strong className="active-sub-text">{item.name}</strong>
            {item.description && <p>{item.description}</p>}
          </>
        ) : (
          <p>{t("box_lose_text")}</p>
        )}
      </div>
    </>
  );
};

export default GiftActiveBox;
