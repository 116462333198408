import { useContext, useState } from "react";
import { useHttp } from "../hooks/http.hook";
import { useTranslation } from "react-i18next";
import { MainContext } from "../contexts/main.context";
import { CSSTransition } from "react-transition-group";
import { notifyError } from "../helpers/notification";
import Button from "./partial/Button";
import Popup from "./Popup";

const ShopBasket = ({
  emptyShoppingCart,
  toEmptyShoppingCart,
  basket,
  setBasket,
}) => {
  const { t } = useTranslation();
  const { mainDetails } = useContext(MainContext);
  const { loading, request } = useHttp();

  const [confirmButton, setConfirmButton] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const resetBasket = () =>
    setBasket({ items: [], totalStars: 0, availableStars: 0 });

  const deleteBasketItem = (item, index) => {
    const items = basket.items.filter((bas, ind) => ind !== index);
    const totalStars = items.reduce((acc, item) => acc + item.price, 0);
    const availableStars = mainDetails.tour.available_stars - totalStars;

    if (totalStars === 0) toEmptyShoppingCart();

    setBasket({ items, totalStars, availableStars });
  };

  const handleClose = () => {
    setShowPopup(false);
    window.location.reload(false);
  };

  const handleConfirm = () => {
    const data = Object.values(
      basket.items.reduce((acc, item) => {
        if (!(item.id in acc))
          acc[item.id] = {
            id: item.id,
            count: 0,
          };
        acc[item.id].count += 1;
        return acc;
      }, {})
    );

    request("/client/tour/checkout", "POST", { basket: data })
      .then((res) => {
        resetBasket();
        setConfirmButton(false);
        if (res === "ok") {
          setShowPopup(true);
        }
      })
      .catch((err) => notifyError(t(err.code, err.message)));
  };

  return (
    <>
      <CSSTransition
        in={showPopup}
        timeout={300}
        classNames="alert"
        unmountOnExit
      >
        <Popup className="popup-sm" close={handleClose}>
          <h2>{t("tour_complete_title")}</h2>
          <p className="my-4">{t("tour_complete_description")}</p>
          <Button
            className="shop-basket-btn"
            primary
            btnBase
            buttonText={t("tour_complete_button")}
            onClick={() => handleClose()}
          />
        </Popup>
      </CSSTransition>
      <div className="shop-basket">
        <div className="shop-basket-header">
          <p>
            <i className="icon-cart" />
            <span className="pl-base">{basket.items.length}</span>
          </p>

          <p>
            {t("balance")}:
            <span className="pl-base">{mainDetails.tour.available_stars}</span>
          </p>
        </div>
        {!emptyShoppingCart ? (
          <p className="shop-basket-empty-text">
            {t("your_shopping_cart_is_empty")}
          </p>
        ) : (
          <>
            {basket.items.map((item, index) => (
              <div key={index} className="shop-basket-item my-3">
                <div className="basket-item-img-wrapper">
                  <img
                    className="basket-item-img"
                    src={item?.prize.images.desktop}
                    alt={item?.prize.name}
                  />
                </div>
                <div className="w-100">
                  <p className="h4">{item?.prize.name}</p>
                  <div className="d-flex align-items-center justify-content-between">
                    <p>
                      {`${t("price")}: `}
                      <span className="pl-base">{item?.price}</span>
                      <span className="pl-base">
                        <i className="icon-star text-primary" />
                      </span>
                    </p>

                    <Button
                      onClick={() => deleteBasketItem(item, index)}
                      outlineSecondary
                      btnBase
                      buttonIcon={"delete"}
                    />
                  </div>
                </div>
              </div>
            ))}

            <div className="shop-basket-footer">
              <div className="d-flex justify-content-between align-items-center">
                <p>
                  {t("total")}:
                  <span className="pl-base">{basket.totalStars}</span>
                </p>
                <p>
                  {t("available")}:
                  <span className="pl-base">{basket.availableStars}</span>
                </p>
              </div>

              {confirmButton ? (
                <>
                  <div className="d-flex justify-content-between align-items-cente mt-16">
                    <Button
                      className="w-100"
                      primary
                      btnSm
                      loading={loading}
                      buttonText={t("confirm")}
                      onClick={() => handleConfirm()}
                    />
                    <Button
                      className="ml-16"
                      danger
                      btnSm
                      buttonIcon="close"
                      onClick={() => setConfirmButton(false)}
                    />
                  </div>
                  <p className="mt-16">{t("checkout_confirmation_text")}</p>
                </>
              ) : (
                <Button
                  className="shop-basket-btn"
                  primary
                  btnBase
                  buttonText={t("checkout")}
                  disabled={!mainDetails.tour.shop_available}
                  onClick={() => setConfirmButton(true)}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ShopBasket;
