import React from "react";
import classNames from "classnames";

const CityLineSvg = ({ className }) => (
  <svg
    width="162"
    height="8"
    viewBox="0 0 162 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    className={classNames({ [`${className}`]: className })}
  >
    <path
      d="M0 4.00012L161.5 3.99976"
      stroke="#555c8e"
      strokeWidth="4"
      strokeDasharray="8 8"
      className="path"
    />
  </svg>
);

export default CityLineSvg;
