import React from "react";
import Lottie from "react-lottie";
import animationData from "../lotties/rotate-phone.json";

const Portrait = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="portrait">
      <div className="portrait-lottie-wrapper">
        <Lottie options={defaultOptions} height={300} width={300} />
      </div>
    </div>
  );
};

export default Portrait;
