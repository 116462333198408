import React from "react";
import { kFormatter, getPercent } from "../../helpers";
import classNames from "classnames";

const Step = ({ city }) => {
  const starsDiff = (city.next_star_score - (city.star_range[city.stars] || 0));
  const starProgress = (city.score - (city.star_range[city.stars] || 0));

  const percent = getPercent(starsDiff, starProgress);

  return (
    <ul className="steps">
      {Object.values(city?.star_range).map((star, index) => {
        let passed = percent;

        if (index + 1 === city.stars || city.stars  === 0) {
          passed = percent;
        }

        if (index < city.stars) {
          passed = 100;
        } else if (index > city.stars) {
          passed = 0;
        }

        const lastPassedEl = Array.from(document.querySelectorAll(".passed")).pop();

        if(lastPassedEl && index + 1 > city.stars && passed === 0) {
          lastPassedEl.classList.add("has-tooltip")
        }

        return (
          <li
            key={index}
            className={classNames("step", {
              passed: passed === 100,
              "has-tooltip": (index + 1 > city.stars && passed !== 0) ||
                             (index + 1 === Object.values(city?.star_range).length && passed === 100)
            })}
          >
            <span
              className="step-helper"
              style={{ width: `${passed}%` }}
              data-progress={passed !== 0 ? city.score : ""} />
            <span className="step-active-circle"  data-star={kFormatter(star)} />
          </li>
        )
      })}
    </ul>
  );
};

export default Step;
