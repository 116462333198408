import React from "react";
import classNames from "classnames";
import Button from "./partial/Button";

const Popup = ({ text, close, className, children }) => {
  return (
    <div
      className={classNames("popup-overlay alert-enter-done", {
        [`${className}`]: className,
      })}
    >
      <div className="popup">
        <Button
          className="popup-btn btn-icon"
          buttonIcon="close"
          onClick={() => close()}
        />
        <div className="popup-body">
          {children && children}
          {text && text}
        </div>
      </div>
    </div>
  );
};

export default Popup;
