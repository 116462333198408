import { useContext } from "react";
import { MainContext } from "../contexts/main.context";
import useOrientationChange from "../hooks/useOrientationChange";
import useMatchMedia from "../hooks/useMatchMedia";
import Portrait from "../components/Portrait";

import TourList from "./TourList";
import CityTour from "./CityTour";
import Shop from "./Shop";
import Info from "./Info";
import History from "./History";

function Main() {
  const { mainDetails } = useContext(MainContext);
  const { isPortrait } = useOrientationChange();
  const isMobile = useMatchMedia("991.98");

  const activeTab = mainDetails.activeTab;

  if (!isPortrait && isMobile) return <Portrait />;

  if (mainDetails.isJoined()) {
    switch (activeTab) {
      case 1:
        return <History />;
      case 2:
        return <Shop />;
      case 3:
        return <TourList />;
      case 4:
        return <Info />;
      default:
        return <CityTour />;
    }
  }

  if (activeTab === 1) {
    return <History />;
  }

  return <TourList />;
}

export default Main;
