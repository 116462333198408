import React from "react";
import classNames from "classnames";
import LoaderSvg from "../svgImages/LoaderSvg";

const Button = ({
  className,
  buttonText,
  buttonIcon,
  primary,
  secondary,
  danger,
  outlinePrimary,
  outlineSecondary,
  btnSm,
  btnXs,
  btnBase,
  onClick,
  disabled,
  loading,
}) => {
  return (
    <button
      className={classNames("btn", {
        "btn-primary": primary,
        "btn-secondary": secondary,
        "btn-danger": danger,
        "btn-outline-primary": outlinePrimary,
        "btn-outline-secondary": outlineSecondary,
        "btn-sm": btnSm,
        "btn-xs": btnXs,
        "btn-base": btnBase,
        [`${className}`]: className,
      })}
      aria-label={buttonIcon ? buttonIcon : ""}
      disabled={disabled}
      onClick={onClick}
    >
      {buttonIcon && <i className={`icon-${buttonIcon}`} />}
      {!loading ? buttonText : <LoaderSvg />}
    </button>
  );
};

export default Button;
