import React from "react";
import { useTranslation } from "react-i18next";
import {
  STAR_ACTIVE_SVG,
  DIAMOND_BOX_IMG,
  GOLD_BOX_IMG,
  SILVER_BOX_IMG,
  BOX_TYPES,
} from "../constants";

import { dateFormat } from "../helpers";

const TablePrizes = ({ history }) => {
  const { t } = useTranslation();

  return (
    <div className="table-responsive">
      <table className="table history-table">
        <thead>
          <tr>
            <th>
              <div>{t("prize_name")}</div>
            </th>
            <th>
              <div>{t("unlock_date")}</div>
            </th>
            <th>
              <div>{t("status")}</div>
            </th>
            <th>
              <div>{t("from")}</div>
            </th>
            <th>
              <div>{t("price")}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {history.checkout_result.length === 0 &&
            history.boxes_result.length === 0 && (
              <tr>
                <td colSpan="5">
                  <h2 className="text-center">
                    {t("there_are_no_prizes_yet")}
                  </h2>
                </td>
              </tr>
            )}

          {history.checkout_result?.map((item) => {
            const prize = item.prize;

            return (
              <tr key={item.id}>
                <td>
                  <div className="td-prize-name">
                    <img src={prize?.images?.desktop} alt="" />
                    <span>{prize?.name}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <time dateTime={dateFormat(item.created_at)}>
                      {dateFormat(item.created_at)}
                    </time>
                  </div>
                </td>
                <td>
                  <div>{item.status === 1 ? t("available") : t("claimed")}</div>
                </td>
                <td>
                  <div className="td-from">{t("shop")}</div>
                </td>
                <td>
                  <div className="td-price">
                    <img src={STAR_ACTIVE_SVG} alt={prize?.name} />
                    <span>{item.tour_shop.price}</span>
                  </div>
                </td>
              </tr>
            );
          })}

          {history.boxes_result?.map((item) => {
            const box = item.box;
            const prize = item.prize;

            return (
              <tr key={item.id}>
                <td>
                  <div className="td-prize-name">
                    <img src={prize?.images?.desktop} alt={prize?.name} />
                    <span>{prize?.name}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <time dateTime={dateFormat(item.created_at)}>
                      {dateFormat(item.created_at)}
                    </time>
                  </div>
                </td>
                <td>
                  <div className="td-status">
                    <div className="status" data-status={item.status}>
                      {item.status === 1 ? t("available") : t("claimed")}
                    </div>
                  </div>
                </td>
                <td>
                  <div className="td-from">
                    <img
                      src={
                        box.type === BOX_TYPES.silver
                          ? SILVER_BOX_IMG
                          : box.type === BOX_TYPES.gold
                          ? GOLD_BOX_IMG
                          : DIAMOND_BOX_IMG
                      }
                      alt={item.box?.name}
                    />
                    <span>{t(`box_${box.type}_name`)}</span>
                  </div>
                </td>
                <td>
                  <div className="td-price">
                    <span>-</span>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TablePrizes;
