import React from "react";
import { useTranslation } from "react-i18next";
import { activeBoxCount } from "../helpers";
import classNames from "classnames";
import Button from "./partial/Button";

const DetailsBox = ({
  className,
  boxImage,
  boxTitle,
  boxDescription,
  boxOnClick,
  disabled,
  cityBoxes,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames("details-box", {
        [`${className}`]: className,
      })}
    >
      <div className="details-box-img-wrapper">
        <img className="details-box-img" src={boxImage} alt={boxTitle} />
        {activeBoxCount(cityBoxes) && 
          <span className="city-item-box-count">{activeBoxCount(cityBoxes)}x</span>}
      </div>

      <div className="d-flex justify-content-center flex-column">
        <h4 className="details-box-name">{boxTitle}</h4>
        <p className="details-box-description">{boxDescription}</p>
      </div>
      <Button
        className="details-box-btn mt-auto"
        disabled={disabled}
        primary
        btnBase
        buttonText={t("claim_now")}
        onClick={boxOnClick}
      />
    </div>
  );
};

export default DetailsBox;
