import { useCallback, useState } from 'react';
import axios from '../config/axios';

export const useHttp = () => {
    
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = useCallback(async (url, method = 'GET', data = null, headers = {}) => {
    setLoading(true);
    try {
      const response = await axios({
        url,
        method,
        headers: {
          ...headers
        },
        data,
      });

      if (!response.data.status) {
        throw response.data?.payload || 'Something went wrong.';
      }
        
      setLoading(false);
      return response.data?.payload;
    } catch (err) {
      setLoading(false);
      setError(err.message);
      throw err;
    }
  }, []);

  const clearError = useCallback(() => setError(null), []);

  return { loading, request, error, clearError };
}
