import { toast } from "react-toastify";

export const notifyError = (message, options = {}) => {
	toast.error(message, {
		...options,
		theme: 'colored'
	});
};

export const notifySuccess = (message, options = {}) => {
	toast.success(message, {
		...options,
		theme: 'colored'
	});
};

