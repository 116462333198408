import React from "react";
import {
  CHIP_ACTIVE,
  CHIP_DEACTIVATE,
  STAR_ACTIVE,
  STAR_DEACTIVATE,
} from "../constants";
import classNames from "classnames";

const Stars = (props) => {
  const { stars, starCount } = props;

  return (
    <>
      {new Array(starCount).fill().map((_, index) => (
        <div
          key={index}
          className={classNames("star", {
            active: stars > index,
            "has-animation": stars > index
          })}
        >
          <img className="chip-active" src={CHIP_ACTIVE} alt="chip" />
          <img className="star-active" src={STAR_ACTIVE} alt="star" />
          <img className="chip-deactivate" src={CHIP_DEACTIVATE} alt="chip deactivate" />
          <img className="star-deactivate" src={STAR_DEACTIVATE} alt="star deactivate" />
        </div>
      ))}
    </>
  );
};

export default Stars;
