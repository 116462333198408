import React from "react";
import { STAR_ACTIVE_SVG } from "../constants";

const TopShoppingItem = ({ image, name, price, externalId }) => (
    <div className="city-tops-shopping-item tops-item">
      <img src={image} alt="top item" className="tops-item-img city-tops-shopping-item-img" />

      <div className="top-shopping-item-content">
        <h3 className="top-shopping-item-title">{name}</h3>
        <p className="top-shopping-item-prize">
          Price:
          <img className="city-tops-shopping-item-chip" src={STAR_ACTIVE_SVG} alt="chip1" />
          {price}
        </p>
        <p className="tops-item-id">ID: {externalId}</p>
      </div>
    </div>
);


export default TopShoppingItem;
