import React, { useContext, useState } from "react";
import { MainContext } from "../contexts/main.context";
import { useTranslation } from "react-i18next";
import Tabs from "./Tabs";
import Button from "./partial/Button";
import classNames from "classnames";
import Progress from "./partial/Progress";
import useMatchMedia from "../hooks/useMatchMedia";

const Navbar = () => {
  const { t } = useTranslation();
  const { mainDetails, dispatch } = useContext(MainContext);
  const currentTab = mainDetails.activeTab || 0;

  const isMobile = useMatchMedia("991.98");

  const [isToggled, setIsToggled] = useState(false);

  const tabs = [t("map"), t("history"), t("shop"), t("change_tour"), t("info")];

  const mobileNav = [
    { icon: "map-mobile", text: t("map") },
    { icon: "history", text: t("history") },
    { icon: "cart", text: t("shop") },
    { icon: "info", text: t("info") },
  ];

  const mainDetailsTour = mainDetails?.tour;

  function setTab(index) {
    dispatch({
      type: "SET_ACTIVE_TAB",
      payload: index,
    });
  }

  return (
    <>
      <nav className={classNames("navbar")}>
        {isMobile ? (
          <Button
            className="navbar-hamburger-btn"
            buttonIcon="hamburger"
            onClick={() => setIsToggled((prev) => !prev)}
          />
        ) : (
          <span className="navbar-tour-name">
            {mainDetailsTour?.tour?.name}
          </span>
        )}

        {!isMobile && (
          <Tabs tabs={tabs} setTab={setTab} currentTab={currentTab} />
        )}

        <div className="d-flex align-items-center">
          {mainDetailsTour && (
            <>
              <Progress
                width={`${
                  (mainDetailsTour?.stars * 100) / mainDetailsTour?.star_count
                }%`}
                className="progress-total-stars"
                progressIcon="star"
                progressText={`${mainDetailsTour?.stars}/${mainDetailsTour?.star_count}`}
              />
              <Progress
                width={`${
                  (mainDetailsTour?.unlocked_cities_count * 100) /
                  mainDetailsTour?.total_cities_count
                }%`}
                className="progress-total-cities"
                progressIcon="map"
                progressText={`${mainDetailsTour?.unlocked_cities_count}/${mainDetailsTour?.total_cities_count}`}
              />
            </>
          )}

          {!isMobile && (
            <>
              <Button
                className={classNames("navbar-btn", {
                  active: currentTab === tabs.length - 2,
                })}
                onClick={() => setTab(tabs.length - 2)}
                buttonText={t("change_tour")}
                secondary
                btnSm
              />
              <Button
                className={classNames("navbar-btn-icon", {
                  active: currentTab === tabs.length - 1,
                })}
                onClick={() => setTab(tabs.length - 1)}
                buttonIcon="info"
              />
            </>
          )}
        </div>
      </nav>

      <aside className={classNames("aside-navbar", { opened: isToggled })}>
        <Button
          buttonIcon="close"
          className="aside-navbar-close-btn"
          onClick={() => setIsToggled(false)}
        />

        <nav className="aside-navbar-content">
          <span className="navbar-tour-name">
            {mainDetailsTour?.tour?.name}
          </span>

          <ul className="aside-navbar-list">
            {mobileNav.map((nav, index) => (
              <li
                key={index}
                className={classNames("aside-list-item", {
                  active: currentTab === index,
                })}
                onClick={() => {
                  if (index === 3) {
                    setTab(tabs.length - 1);
                  } else {
                    setTab(index);
                  }
                  setIsToggled(false);
                }}
              >
                <span className="aside-list-item-icon">
                  <i className={`icon-${nav.icon}`} />
                </span>
                <span className="aside-list-item-text">{nav.text}</span>
              </li>
            ))}
          </ul>

          <div className="d-flex justify-content-center">
            <Button
              className={classNames("navbar-btn", {
                active: currentTab === tabs.length - 2,
              })}
              onClick={() => {
                setTab(tabs.length - 2);
                setIsToggled(false);
              }}
              buttonText={t("change_tour")}
              secondary
              btnSm
            />
          </div>
        </nav>
      </aside>
    </>
  );
};

export default Navbar;
