import React from "react";
import AccordionItem from "./AccordionItem";
import { ACCORDION_DATA } from "../../constants";
import { useTranslation } from "react-i18next";

const Accordion = () => {
  const { t } = useTranslation();

  return (
    <div className="accordion">
      {ACCORDION_DATA.map(item => (
        <AccordionItem
          key={item.id}
          title={t(item.title)}
          collapsedText={t(item.collapsedText)}
        />
      ))}
    </div>
  );
};

export default Accordion;
