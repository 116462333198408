import { useState, useEffect } from "react";

export default function useOrientationChange() {
  const [isPortrait, setIsPortrait] = useState(
    document.documentElement.clientHeight > document.documentElement.clientWidth
  );

  const handleOrientationChanges = () => {
    setIsPortrait((prev) => !prev);
  };

  useEffect(() => {
    window.addEventListener("orientationchange", handleOrientationChanges);
    return () =>
      window.removeEventListener("orientationchange", handleOrientationChanges);
  }, []);

  return { isPortrait };
}
