import React from "react";

const TopPrizesItem = ({ image, name, externalId }) => (
  <div className="top-prizes-item tops-item">
    <img src={image} alt="item img" className="tops-item-img top-prizes-item-img" />

    <div className="top-prizes-item-content">
      <h3 className="top-prizes-item-title">{name}</h3>
      <p className="tops-item-id">ID: {externalId}</p>
    </div>
  </div>
);


export default TopPrizesItem;
