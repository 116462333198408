import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { dateFormat } from "../helpers";

import Tabs from "../components/Tabs";
import Progress from "../components/partial/Progress";

const HistoryHeader = ({ tourName, dateTime, history, switchTab, index }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState();

  const tabs = [t("history"), t("prizes")];

  const currentTab = tab || 0;

  useEffect(() => {
    switchTab(index, currentTab);
  }, [currentTab]);

  return (
    <div className="history-header">
      <div>
        <h2>{tourName}</h2>
        <time dateTime={dateFormat(dateTime)}>{dateFormat(dateTime)}</time>
      </div>

      <Tabs
        className="history-nav-tabs"
        tabs={tabs}
        setTab={setTab}
        currentTab={currentTab}
      />

      <div className="d-flex align-items-center flex-xs-column-down">
        <Progress
          width={`${(history.stars * 100) / history.star_count}%`}
          className="history-progress"
          progressIcon="star"
          progressText={`${history.stars}/${history.star_count}`}
        />
        <Progress
          width={`${
            (history.unlocked_cities_count * 100) / history.total_cities_count
          }%`}
          className="history-progress"
          progressIcon="map"
          progressText={`${history.unlocked_cities_count}/${history.total_cities_count}`}
        />
      </div>
    </div>
  );
};

export default HistoryHeader;
