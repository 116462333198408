import React from "react";
import TopShoppingItem from "./TopShoppingItem";
import useMatchMedia from "../hooks/useMatchMedia";
import { useTranslation } from "react-i18next";

const TopShopping = ({ topShopping }) => {
  const isMobile = useMatchMedia("991.98");
  const { t } = useTranslation();

  return (
    <div className="city-tops-shopping city-tops-block">
      <h2 className="tops-title">{t("top_shopping")}</h2>

      <div className="tops-item-wrapper">
        {topShopping?.map(item => (
          <TopShoppingItem
            key={item.id}
            name={item.name}
            price={item.price}
            externalId={item.external_id}
            image={isMobile ? item.images.mobile : item.images.desktop}
          />
        ))}
      </div>
    </div>
  );
};

export default TopShopping;
