import React from "react";
import classNames from "classnames";

const Progress = ({ className, progressIcon, progressText, width }) => (
  <div
    className={classNames("progress-wrapper", {
      [`${className}`]: className,
    })}
  >
    {(progressIcon || progressText) && (
      <div className="progress-data">
        {progressIcon && <i className={`progress-icon icon-${progressIcon}`} />}
        {progressText && <span className="progress-text">{progressText}</span>}
      </div>
    )}
    <div className="progress">
      <div className="progress-bar" style={{ width: width }} />
    </div>
  </div>
);

export default Progress;
