import { useState, useContext, Fragment, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { useHttp } from "../hooks/http.hook";
import { MainContext } from "../contexts/main.context";
import { citiesChunks, sendPostMessageClientHeight, updateStatusReaded } from "../helpers";

import classNames from "classnames";
import CityDetails from "../views/CityDetails";
import CityItem from "../components/CityItem";
import CityNextLineSvg from "../components/svgImages/CityNextLineSvg";
import {
  CITY_ROW_ITEM_COUNT_DESKTOP,
  CITY_ROW_ITEM_COUNT_MOBILE
} from "../constants";
import useMatchMedia from "../hooks/useMatchMedia";
import CityLineSvg from "../components/svgImages/CityLineSvg";
import Title from "../components/partial/Title";
import { useTranslation } from "react-i18next";
import Accordion from "../components/partial/Accordion";
import TopShopping from "../components/TopShopping";
import TopPrizes from "../components/TopPrizes";

function CityTour() {
  const { t } = useTranslation();
  const [selectedCity, setSelectedCity] = useState();
  const [clickedCityTop, setClickedCityTop] = useState(0);
  const [topShopping, setTopShopping] = useState();
  const [topPrizes, setTopPrizes] = useState();
  const { mainDetails } = useContext(MainContext);
  const { request } = useHttp();
  const isMobile = useMatchMedia("991.98");

  const cityRowItemCount = isMobile
    ? CITY_ROW_ITEM_COUNT_MOBILE
    : CITY_ROW_ITEM_COUNT_DESKTOP;

  useEffect(() => {

    updateStatusReaded(mainDetails?.unreadEvents, request);

    getShoppingData();

    sendPostMessageClientHeight();

  }, []);

  const getShoppingData = () => {
    request("/partner/shopping-prizes").then(
      (res) => {
        setTopShopping(res.top_shopping);
        setTopPrizes(res.top_prizes);
      }
    );
  };

  const handleClose = () => {
    document.body.classList.remove("overflow-hidden");
    setSelectedCity(null);
  };

  const handleOpen = (city) => {
    document.body.classList.add("overflow-hidden");
    setSelectedCity(city);
  };

  useEffect(() => {
    const cityPopupTop = document.querySelector(".city-details-popup")?.getBoundingClientRect().top;
    const selectedCityId = selectedCity?.id;
    const clickedCityTopPos = document.querySelector(`#id-${selectedCityId}`)?.getBoundingClientRect().top;
    setClickedCityTop(clickedCityTopPos);
    console.log(clickedCityTop);

    if (selectedCity === null) {
      window.parent.postMessage({ action: "clickedCityTop", clickedCityTop: clickedCityTop }, "*");
    } else {
      window.parent.postMessage({ action: "cityPopupTop", cityPopupTop: cityPopupTop }, "*");
    }
  }, [selectedCity]);


  return (
    <div className="map">

        <CSSTransition
          in={!!selectedCity}
          timeout={300}
          classNames="alert"
          unmountOnExit
        >
          <CityDetails className="city-details-popup" city={selectedCity} close={handleClose} />
        </CSSTransition>

        <div className="city-item-wrapper">
          {citiesChunks(mainDetails?.cities, cityRowItemCount).map(
            (cityChunk, index) => (
              <div
                className={classNames("city-item-row", {
                  "unlocked-row": cityChunk.completed
                })}
                key={index}
              >
                {cityChunk.items.map((city, index) => {
                  const tourCity = city.tour_city;
                  return (
                    <Fragment key={index}>
                      <CityItem
                        id={city.id}
                        className={classNames({
                          current: city.is_current,
                          unlocked: city.status !== 1,
                          "animation-star":
                            city.id === mainDetails.animationStar(),
                          "animation-city":
                            city.id === mainDetails.animationCity()
                        })}
                        cityImage={
                          city.status !== 1
                            ? tourCity.images.end
                            : city.is_current
                              ? tourCity.images.animation
                              : tourCity.images.start
                        }
                        cityStars={city.stars}
                        cityStarsCount={city.star_count}
                        cityName={tourCity.name}
                        cityBoxs={city.client_boxes}
                        isCurrent={city.is_current}
                        onClick={() => handleOpen(city)}
                      />
                    </Fragment>
                  );
                })}

                {index % 2 === 0 ? (
                  isMobile ? (
                    <CityLineSvg className="city-item-row-line right" />
                  ) : (
                    <CityNextLineSvg className="city-item-row-line right" />
                  )
                ) : isMobile ? (
                  <CityLineSvg className="city-item-row-line left" />
                ) : (
                  <CityNextLineSvg className="city-item-row-line left" />
                )}
              </div>
            )
          )}
        </div>

        <Title title={t("accordion_title")} text={t("accordion_text")} />

        <Accordion />

      <Title title={t("tops_title")} text={t("tops_text")} />

      <div className="city-tops">
        <TopShopping topShopping={topShopping} />
        <TopPrizes topPrizes={topPrizes} />
      </div>

    </div>
  );
}

export default CityTour;
