import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHttp } from "../hooks/http.hook";
import { MainContext } from "../contexts/main.context";
import { sendPostMessageClientHeight } from "../helpers";
import Card from "../components/Card";
import useMatchMedia from "../hooks/useMatchMedia";

function TourList() {
  const { t } = useTranslation();
  const [ tourList, setTourList ] = useState();
  const { request } = useHttp();
  const { mainDetails, getMainDetails } = useContext(MainContext);

  const isMobile = useMatchMedia("991.98");

  useEffect(() => {
    getCityTourList();
  }, []);

  const getCityTourList = async () => {
    request("/client/tour/list").then((res) => {
      setTourList(res);
      sendPostMessageClientHeight()
    });
  };

  const finish = async () => {
    request("/client/tour/finish", "POST").then(() => getMainDetails());
  };

  const join = async (tourId) => {
    if (mainDetails.isGuest()) {
      request("/client/reg_me", "POST", { tour_id: tourId }).then(() =>
        getMainDetails()
      );
    } else {
      const data = {
        tour_id: tourId,
        force: true,
      };

      request("/client/tour/join", "POST", data).then(() => getMainDetails());
    }
  };

  return (
    <>
      <section className="section-tour">
        <h2>{t("tour_list_name")}</h2>
        <p>{t("tour_list_description")}</p>
      </section>

      {tourList?.map((tour) => (
        <article key={tour.id} className="tour-list-item">
          <Card
            image={!isMobile ? tour.images.desktop : tour.images.mobile}
            buttonText={
              tour.id === mainDetails.client?.active_tour_id
                ? t("finish")
                : t("join")
            }
            buttonOnClick={() =>
              tour.id === mainDetails.client?.active_tour_id
                ? finish()
                : join(tour.id)
            }
          />

          <div className="ml-sm-24-up mt-16">
            <h3 className="h2">{tour.name}</h3>
            <div dangerouslySetInnerHTML={{ __html: tour.description }}></div>
          </div>
        </article>
      ))}
    </>
  );
}

export default TourList;
