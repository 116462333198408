import { MainProvider } from "./contexts/main.context";
import { ToastContainer } from "react-toastify";
import Navbar from "./components/Navbar";
import Main from "./views/Main";

import "./assets/styles/styles.scss";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <MainProvider>
      <div className="app">
        <div className="container">
          <Navbar />
          <Main />
        </div>
      </div>
      <ToastContainer rtl />
    </MainProvider>
  );
}

export default App;
