import React from "react";
import classNames from "classnames";

const Tabs = ({ tabs, setTab, currentTab, className }) => {
  return (
    <ul
      className={classNames("nav nav-tabs", {
        [`${className}`]: className,
      })}
    >
      {tabs.map((tab, index) => (
        <li
          key={tab}
          onClick={() => setTab(index)}
          className={classNames("nav-item", { active: index === currentTab })}
        >
          {tab}
        </li>
      ))}
    </ul>
  );
};

export default Tabs;
