import cityTourBoxImg from "../assets/images/city-tour-box.png";

import chipActiveImg from "../assets/images/star/chip-active.png";
import starActiveImg from "../assets/images/star/star-active.png";
import chipDeactivateImg from "../assets/images/star/chip-deactivate.png";
import starDeactivateImg from "../assets/images/star/star-deactivate.png";

import silverBoxImg from "../assets/images/boxes/silver.png";
import goldBoxImg from "../assets/images/boxes/gold.png";
import diamondBoxImg from "../assets/images/boxes/diamond.png";

import boxActiveEmpty from "../assets/images/boxes/box-active-empty.svg";

import starActiveSvg from "../assets/images/star-active.svg";

export const CITY_TOUR_BOX = cityTourBoxImg;

export const CHIP_ACTIVE = chipActiveImg;
export const STAR_ACTIVE = starActiveImg;
export const CHIP_DEACTIVATE = chipDeactivateImg;
export const STAR_DEACTIVATE = starDeactivateImg;

export const CITY_ROW_ITEM_COUNT_DESKTOP = 4;
export const CITY_ROW_ITEM_COUNT_MOBILE = 2;
export const STAR_RECEIVED = "star_received";
export const CITY_UNLOCKED = "city_unlocked";

export const SILVER_BOX_IMG = silverBoxImg;
export const GOLD_BOX_IMG = goldBoxImg;
export const DIAMOND_BOX_IMG = diamondBoxImg;

export const BOX_ACTIVE_EMPTY = boxActiveEmpty;

export const STAR_ACTIVE_SVG = starActiveSvg;

export const BOX_TYPES = {
  silver: "silver",
  gold: "gold",
  diamond: "diamond",
};

export const TERMS_CONDITIONS_TAB_INDEX = 4;

export const ACCORDION_DATA = [
  {
    id: 1,
    title: 'casino_tour_stars_title',
    collapsedText: 'casino_tour_stars_description'
  },
  {
    id: 2,
    title: 'casino_tour_prizes_title',
    collapsedText: 'casino_tour_prizes_description'
  },
  {
    id: 3,
    title: 'casino_tour_terms_and_conditions_title',
    collapsedText: 'casino_tour_terms_and_conditions_description'
  }
]
