import React from "react";
import classNames from "classnames";

const CityNextLineSvg = ({ className }) => (
  <svg
    width="148"
    height="400"
    viewBox="0 0 148 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames({ [`${className}`]: className })}
  >
    <path
      d="M148 396L100 396C46.9806 396 3.99997 353.019 3.99998 300L3.99999 100C4 46.9806 46.9807 3.99998 100 3.99999L148 3.99999"
      stroke="#555C8E"
      strokeWidth="4"
      strokeDasharray="8 8"
      className="path"
    />
  </svg>
);

export default CityNextLineSvg;
