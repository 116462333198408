import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CITY_TOUR_BOX } from "../constants";
import { activeBoxCount } from "../helpers";
import Button from "./partial/Button";
import classNames from "classnames";
import Stars from "./Stars";
import CityRectLineSvg from "./svgImages/CityRectLineSvg";
import CityLineSvg from "./svgImages/CityLineSvg";

const CityItem = ({
  id,
  className,
  onClick,
  cityImage,
  cityName,
  cityBoxs,
  cityStars,
  cityStarsCount,
  isCurrent,
}) => {
  const { t } = useTranslation();
  const currentRef = useRef(null);

  useEffect(() => {
    const currentRefTop = currentRef?.current?.getBoundingClientRect().top;

    currentRefTop &&
      window.scrollTo({
        top: currentRefTop - 180,
      });
  }, []);

  return (
    <figure
      id={`id-${id}`}
      className={classNames("city-item", {
        [`${className}`]: className,
      })}
      onClick={onClick}
      ref={isCurrent ? currentRef : null}
    >
      <div className="city-item-img-wrapper">
        <img className="city-item-img" src={cityImage} alt={cityName} />

        {cityBoxs.length > 0 && (
          <div className="city-item-box">
            <img src={CITY_TOUR_BOX} alt="box" />
            {activeBoxCount(cityBoxs) && 
              <span className="city-item-box-count">{activeBoxCount(cityBoxs)}x</span>}
          </div>
        )}
      </div>

      {/*<CurvedTextSvg className="city-item-curved-text" curvedText={cityName} />*/}
      <CityLineSvg className="city-item-line" />
      <CityRectLineSvg className="city-item-rect-line" />

      <figcaption className="city-item-body">
        <div className="city-item-score">
          <Stars stars={cityStars} starCount={cityStarsCount} />
        </div>
        <h5 className="city-item-name">{cityName}</h5>
        <Button
          secondary
          btnXs
          buttonText={t("view")}
          className="city-item-btn"
        />
      </figcaption>
    </figure>
  );
};

export default CityItem;
