export const mainReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_ACTIVE_TAB": {
      return {
        ...state,
        activeTab: payload,
      };
    }

    case "SET_BOX_DATA": {
      return {
        ...state,
        box: payload,
      };
    }

    case "SET_MAIN_DETAILS":
      return {
        ...state,
        client: payload?.client_info,
        tour: payload?.client_tour,
        cities: payload?.tour_cities,
        unreadEvents: payload?.unread_events,
      };

    case "UPDATE_CLIENT":
      return {
        ...state,
        client: {
          ...payload,
        },
        cities: state.cities.map((city) => {
          city.is_current = +(
            payload.current_active_client_city_id === city.id
          );
          return city;
        }),
      };

    case "UPDATE_CITY_BOX_STATUS":
      return {
        ...state,
        cities: state.cities.filter((city) => {
          if (city.id === payload?.client_city_id) {
            city.client_boxes.filter((box) => {
              if (box.id === payload?.id) {
                box.status = payload?.status;
              }
            });
          }
          return city;
        }),
      };

    case "UPDATE_UNREAD_EVENTS":
      return {
        ...state,
        unreadEvents: payload,
      };

    default:
      return state;
  }
};
