import React from "react";

const Title = ({ title, text }) => (
    <div className="casino-city-tour-title">
      <div className="title-wrapper">
        <span className="line left" />
        <h2 className="title">{title}</h2>
        <span className="line right" />
      </div>

      {text && <p className="text">{text}</p>}
    </div>
  );

export default Title;
