import { useEffect, useState } from "react";
import { useHttp } from "../hooks/http.hook";
import TableHistory from "../components/TableHistory";
import TablePrizes from "../components/TablePrizes";
import HistoryHeader from "../components/HistoryHeader";
import { sendPostMessageClientHeight } from "../helpers";

function History() {
  const [history, setHistory] = useState();
  const [historyItems, setHistoryItems] = useState(
    new Array(history?.length).fill(false)
  );
  const { request } = useHttp();

  useEffect(() => {
    request("/client/tour/history").then((res) => setHistory(res.client_tours));
  }, []);

  const switchTab = (index, currentTab) => {
    historyItems[index] = !!currentTab;
    setHistoryItems([...historyItems]);
    sendPostMessageClientHeight()
  };

  return (
    <>
      {history?.map((item, index) => (
        <section key={item.id} className="history">
          <HistoryHeader
            tourName={item.tour?.name}
            dateTime={item.started_at}
            history={item}
            switchTab={switchTab}
            index={index}
          />
          {!historyItems[index] ? (
            <TableHistory history={item} />
          ) : (
            <TablePrizes history={item} />
          )}
        </section>
      ))}
    </>
  );
}

export default History;
