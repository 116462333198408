import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHttp } from "../hooks/http.hook";
import { MainContext } from "../contexts/main.context";
import { DIAMOND_BOX_IMG, GOLD_BOX_IMG, SILVER_BOX_IMG } from "../constants";

import Button from "./partial/Button";
import DetailsBox from "./DetailsBox";
import Step from "./partial/Step";

const Details = ({ city, next }) => {
  const tourCity = city?.tour_city;
  const { t } = useTranslation();
  const { dispatch } = useContext(MainContext);
  const { request } = useHttp();
  
  const setCurrentCity = async (id) => {
    const data = { client_city_id: id };
    request("/client/tour/current_city", "POST", data).then(res =>
      dispatch({ type: "UPDATE_CLIENT", payload: res })
    );
  };

  const handleClaim = (box) => {
    dispatch({ type: "SET_BOX_DATA", payload: box });
    next();
  };

  const boxes = city?.client_boxes.reduce(
    (acc, value) => {
      if (!(value.box.type in acc)) acc[value.box.type] = [];
      if (value.status === 1) {
        acc[value.box.type].push(value);
      }
      return acc;
    },
    { silver: [], gold: [], diamond: [] }
  );

  return (
    <>
      {city && (
        <div className="city-details">
          <div
            className="city-details-cover-img"
            style={{ backgroundImage: `url(${tourCity.images.cover})` }}
          />

          <div className="city-details-content">
            <img
              className="city-details-img"
              src={tourCity.images.start}
              alt={tourCity.name}
            />

            <div className="ml-sm-24-up mt-sm-24-down">
              <h2 className="text-blue">{tourCity.name}</h2>
              <p className="city-details-description">{tourCity.description}</p>
            </div>
          </div>

          <div className="city-details-score-wrapper">
            <Step city={city} />
          </div>

          {city.status === 2 && city.is_current === 0 && (
            <div className="city-details-btn-wrapper">
              <Button
                primary
                btnBase
                buttonText={t("go_to_this_city")}
                onClick={() => setCurrentCity(city.id)}
              />
            </div>
          )}

          <div className="details-box-wrapper">
            <DetailsBox
              disabled={!boxes.silver[0]?.is_available}
              cityBoxes={boxes.silver}
              boxImage={SILVER_BOX_IMG}
              boxTitle={t("box_silver_name")}
              boxDescription={t("box_silver_description")}
              boxOnClick={() => handleClaim(boxes.silver[0])}
            />

            <DetailsBox
              disabled={!boxes.gold[0]?.is_available}
              cityBoxes={boxes.gold}
              boxImage={GOLD_BOX_IMG}
              boxTitle={t("box_gold_name")}
              boxDescription={t("box_gold_description")}
              boxOnClick={() => handleClaim(boxes.gold[0])}
            />

            <DetailsBox
              disabled={!boxes.diamond[0]?.is_available || boxes.diamond[0]?.is_available === -1}
              cityBoxes={boxes.diamond}
              boxImage={DIAMOND_BOX_IMG}
              boxTitle={t("box_diamond_name")}
              boxDescription={t("box_diamond_description")}
              boxOnClick={() => handleClaim(boxes.diamond[0])}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Details;
