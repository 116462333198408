import React from "react";
import Button from "./partial/Button";
import classNames from "classnames";

const Card = ({
  style,
  title,
  price,
  image,
  buttonText,
  buttonOnClick,
  buttonClassName,
  buttonDisabled,
}) => {
  return (
    <div className="card" style={style}>
      <div className="card-img-wrapper">
        <img className="card-img" src={image} alt={title} />
      </div>

      {price && (
        <p className="card-price">
          <i className="icon-star text-primary" /> <span>{price}</span>
        </p>
      )}
      <div className="card-inner">
        <div className="d-flex align-items-center justify-content-between">
          <p className="card-title">{title}</p>
        </div>

        <Button
          outlinePrimary
          btnBase
          buttonText={buttonText}
          className={classNames("card-btn", {
            [`${buttonClassName}`]: buttonClassName,
          })}
          disabled={buttonDisabled}
          onClick={buttonOnClick}
        />
      </div>
    </div>
  );
};

export default Card;
