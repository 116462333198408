import React from "react";
import classNames from "classnames";

const CityRectLineSvg = ({ className }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 222 302"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    className={classNames({ [`${className}`]: className })}
  >
    <rect
      x="3"
      y="3"
      width="98%"
      height="98%"
      rx="24"
      stroke="#65D1FF"
      strokeWidth="5"
      className="rect"
    />
  </svg>
);

export default CityRectLineSvg;
