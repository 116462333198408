import React from "react";
import { useTranslation } from "react-i18next";
import { dateFormat, getBox } from "../helpers";
import { STAR_ACTIVE_SVG } from "../constants";

const TableHistory = ({ history }) => {
  const { t } = useTranslation();

  return (
    <div className="table-responsive">
      <table className="table history-table">
        <thead>
          <tr>
            <th>
              <div>{t("city_name")}</div>
            </th>
            <th>
              <div>{t("unlock_date")}</div>
            </th>
            <th>
              <div>{t("stars")}</div>
            </th>
            <th>
              <div>{t("boxes")}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {history.client_cities.map((item) => (
            <tr key={item.id}>
              <td>
                <div className="td-city-name">
                  <div className="img-wrapper">
                    <img
                      src={item.tour_city.images.end}
                      alt={item.tour_city?.name}
                    />
                    {item.score === item.total_score && (
                      <span className="completed">
                        <i className="icon-check" />
                      </span>
                    )}
                  </div>
                  <span>{item.tour_city?.name}</span>
                </div>
              </td>
              <td>
                <div>
                  <time dateTime={dateFormat(item?.created_at)}>
                    {dateFormat(item?.open_date)}
                  </time>
                </div>
              </td>
              <td>
                <div className="td-stars">
                  {new Array(item.stars).fill().map((star, index) => (
                    <img key={index} src={STAR_ACTIVE_SVG} alt="star" />
                  ))}
                </div>
              </td>
              <td>
                <div className="td-boxes">
                  <div className="boxes-item">
                    <span>{t("silver_box")}</span>
                    <span>
                      <i className="icon-box" />
                    </span>
                    <span className="box-count">
                      {getBox(item.client_boxes, "silver").openedBoxCount +
                        "/" +
                        getBox(item.client_boxes, "silver").boxTypeCount}
                    </span>
                  </div>
                  <div className="boxes-item">
                    <span>{t("gold_box")}</span>
                    <span>
                      <i className="icon-box" />
                    </span>
                    <span className="box-count">
                      {getBox(item.client_boxes, "gold").openedBoxCount +
                        "/" +
                        getBox(item.client_boxes, "gold").boxTypeCount}
                    </span>
                  </div>
                  <div className="boxes-item">
                    <span>{t("diamond_box")}</span>
                    <span>
                      <i className="icon-box" />
                    </span>
                    <span className="box-count">
                      {getBox(item.client_boxes, "diamond").openedBoxCount +
                        "/" +
                        getBox(item.client_boxes, "diamond").boxTypeCount}
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableHistory;
