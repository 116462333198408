import { useEffect, useState } from "react";
import { useHttp } from "../hooks/http.hook";
import { sendPostMessageClientHeight } from "../helpers";

function Info() {
  const [termsInfo, setTermsInfo] = useState();
  const { request } = useHttp();

  useEffect(() => {
    request("/page?page=terms").then((res) => {
      setTermsInfo(res);
      sendPostMessageClientHeight();
    });

  }, []);

  return (
    <div className="terms-conditions">
      <section className="section-terms-conditions">
        <h2 className="terms-conditions-title">{termsInfo?.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: termsInfo?.content }} />
      </section>
    </div>
  );
}

export default Info;
